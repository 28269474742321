<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <!-- <Button
          label="Add New"
          icon="pi pi-plus"
          class="ml-2 p-button-success"
          @click="$router.push('gifts/add')"
        /> -->
        <!-- <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="show {first} to {last} from {totalRecords}"
    >
      <template #header>
        <div class="table-header">
          Geschenke Aufführen

          <div style="text-align: left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="Select Columns"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>Keine Daten</template>
      <template #loading>Daten werden geladen, bitte warten... </template>
      <Column
        filterMatchMode="contains"
        field="id"
        header="ID"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['id']"
            class="p-column-filter"
            placeholder="Search By ID"
          />
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="name"
        header="Name"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['name']"
            class="p-column-filter"
            placeholder="Search By Name"
            filterMatchMode="contains"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="phone"
        header="Telefon"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['phone']"
            class="p-column-filter"
            placeholder="Search By Telefon"
            filterMatchMode="contains"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="total"
        header="Total"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps"> {{ slotProps.data.total }}€ </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="id"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('orders/edit/' + slotProps.data.id)"
            icon="pi pi-eye"
            class="p-button-info p-mr-2 p-button-rounded"
          ></Button>

          <!-- <Button
            type="button"
            icon="pi pi-lock"
            v-if="!slotProps.data.done"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning ml-2 p-button-rounded"
          ></Button> -->

          <Button
            type="button"
            @click="$router.push('orders/show/' + slotProps.data.id)"
            icon="pi pi-print"
            class="p-button-success ml-2 p-button-rounded"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},
    };
  },
  methods: {
    getData() {
      this.$http.get(`carts`).then(
        (response) => {
          this.loading = false;
          const list = response.data.data;

          for (let item of list) {
            item.id = this.$codePadding(item.id);
            item.name = item.fname + ' ' + item.lname;
          }

          this.list = list;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    deleteItem(id) {
      this.$http.post(`gifts/update/${id}`, { done: true }).then(
        () => {
          this.getData();
          this.$toast.add({
            severity: 'error',
            summary: 'Done Successfully',
            detail: 'Updated Successfully',
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
